import React from 'react';
import MainLayout from '../layouts/main';
import { Box, Button, Card, CardContent, List, ListItem, ListItemText } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TwitterIcon from '@mui/icons-material/Twitter';

const TikTokIcon = () => {
    return (
      <svg
        fill="#000000"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 50 50"
        width="100"
        height="100"
      >
        <path d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z" />
      </svg>
    );
  };

const SpotifyIcon = () => {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 50 50">
            <path d="M25.009,1.982C12.322,1.982,2,12.304,2,24.991S12.322,48,25.009,48s23.009-10.321,23.009-23.009S37.696,1.982,25.009,1.982z M34.748,35.333c-0.289,0.434-0.765,0.668-1.25,0.668c-0.286,0-0.575-0.081-0.831-0.252C30.194,34.1,26,33,22.5,33.001 c-3.714,0.002-6.498,0.914-6.526,0.923c-0.784,0.266-1.635-0.162-1.897-0.948s0.163-1.636,0.949-1.897 c0.132-0.044,3.279-1.075,7.474-1.077C26,30,30.868,30.944,34.332,33.253C35.022,33.713,35.208,34.644,34.748,35.333z M37.74,29.193 c-0.325,0.522-0.886,0.809-1.459,0.809c-0.31,0-0.624-0.083-0.906-0.26c-4.484-2.794-9.092-3.385-13.062-3.35 c-4.482,0.04-8.066,0.895-8.127,0.913c-0.907,0.258-1.861-0.272-2.12-1.183c-0.259-0.913,0.272-1.862,1.184-2.12 c0.277-0.079,3.854-0.959,8.751-1c4.465-0.037,10.029,0.61,15.191,3.826C37.995,27.328,38.242,28.388,37.74,29.193z M40.725,22.013 C40.352,22.647,39.684,23,38.998,23c-0.344,0-0.692-0.089-1.011-0.275c-5.226-3.068-11.58-3.719-15.99-3.725 c-0.021,0-0.042,0-0.063,0c-5.333,0-9.44,0.938-9.481,0.948c-1.078,0.247-2.151-0.419-2.401-1.495 c-0.25-1.075,0.417-2.149,1.492-2.4C11.729,16.01,16.117,15,21.934,15c0.023,0,0.046,0,0.069,0 c4.905,0.007,12.011,0.753,18.01,4.275C40.965,19.835,41.284,21.061,40.725,22.013z"></path>
        </svg>
    );
}

const containerStyles = {
    position: 'relative',
    width: '100vw',
    minHeight: '100vh',
    overflow: 'hidden', // Ensure the pseudo-element doesn't overflow
    '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundImage: 'url("/images/court.jpeg")',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        filter: 'blur(12px)',
        zIndex: -1 // Keep the pseudo-element below the actual content
    },
};

const primaryButtonStyles = {
    backgroundColor: '#f36501',
    borderRadius: '50px',
    width: '200px',
    padding: '10px',
    transition: 'background-color 0.3s', // Add transition for a smooth color change
    '&:hover': {
        backgroundColor: '#ff9900', // Change the background color on hover
    },
};

const secondaryButtonStyles = {
    marginLeft: '20px',
    borderRadius: '50px',
    color: '#f36501',
    borderColor: '#f36501',
    width: '200px',
    padding: '10px',
    transition: 'color 0.3s, border-color 0.3s', // Add transition for color and border change
    '&:hover': {
        color: '#ff9900', // Change the text color on hover
        borderColor: '#ff9900', // Change the border color on hover
    },
};

const TweetEmbed: React.FC = () => {
    return (
      <blockquote className="twitter-tweet">
        <p lang="qst" dir="ltr">Yup <a href="https://t.co/P5Jo77AVuJ">https://t.co/P5Jo77AVuJ</a></p>
        &mdash; LaMarcus Aldridge (@aldridge_12) 
        <a href="https://twitter.com/aldridge_12/status/1702446457305846088?ref_src=twsrc%5Etfw">
          September 14, 2023
        </a>
      </blockquote>
    );
  };

export default function Home() {
    const truncateText = () => {
        const textElement = document.getElementById("text") as HTMLParagraphElement;
        const contentElement = document.getElementById("content") as HTMLElement;
    
        let fullText = textElement.innerText;
        let truncatedText = fullText;
    
        textElement.innerText = truncatedText;
    
        while (textElement.offsetHeight > contentElement.offsetHeight) {
            truncatedText = truncatedText.substr(0, truncatedText.length - 5); // remove the last 5 characters
            textElement.innerText = truncatedText + "...";
        }
    }
    
    window.onload = () => {
        truncateText();
    };

    return (
        <MainLayout>
            <Box sx={containerStyles}>
                <div id="content" style={{marginTop: "48vh", paddingLeft: "10%", paddingRight: "20%", height: "10em", lineHeight: "3em", overflow: "hidden"}}>
                    <p id="text" style={{color: "#fff", fontSize: "24px"}}> Step onto the hardwood with us as we dive deep into the world of basketball and explore the biggest stories and the latest news. Tune in every Tuesday as we provide a fan&apos;s perspective on a world largely dominated by reporters and athletes.</p>
                </div>
                <div style={{display: "flex", flexDirection: "row", paddingLeft: "10%", paddingTop: "30px"}}>
                    <a href="https://open.spotify.com/show/4Bf3DBl8kyNYOlIzBUiXQ8?si=9394bd1bfa634817">
                        <Button variant="contained" sx={primaryButtonStyles}>Subscribe</Button>
                    </a>
                    <a href="https://open.spotify.com/show/4Bf3DBl8kyNYOlIzBUiXQ8?si=9394bd1bfa634817">
                        <Button variant="outlined" sx={secondaryButtonStyles}>Listen</Button>
                    </a>
                </div>
            </Box>
            <Box
                sx={{
                height: '100px', // Adjust the height as needed
                background: '#f36501',
                clipPath: 'polygon(0 0, 100% 100%, 100% 0)'
                }}
            />
            <Box>
                <h1 style={{textAlign: "center", fontSize: "50px", color: "#000"}}>Follow Us</h1>
                <div style={{display: "flex", flexDirection: "row", paddingTop: "50px", paddingBottom: "50px", paddingLeft: "250px", paddingRight: "250px", justifyContent: "space-between"}}>
                    <a href="https://www.instagram.com/dishandswishpod/">
                        <InstagramIcon style={{fontSize: 100, color: "#000"}}/>
                    </a>
                    <a href="https://www.youtube.com/@DishandSwish">
                        <YouTubeIcon style={{fontSize: 100, color: "#000"}}/>
                    </a>
                    <a href="https://open.spotify.com/show/4Bf3DBl8kyNYOlIzBUiXQ8?si=9394bd1bfa634817">
                        <SpotifyIcon />
                    </a>
                    <a href="https://twitter.com/DishAndSwishPod">
                        <TwitterIcon style={{fontSize: 100, color: "#000"}}/>
                    </a>
                    <a href="https://www.tiktok.com/@dishandswishpod?_d=secCgYIASAHKAESPgo8c8G%2FV%2BikvSq%2B0k8vXS%2FprPGtzP2BKe7bJvhnsc6noWZZdyWocaVCrBJiTPZcsHevmVtFP%2FcLeETmSsnvGgA%3D&object_id=7273671089656792107&page_open_method=scan_code&schema_type=4&sec_uid=MS4wLjABAAAAh8CJCOcljoWOXZDhRk48NZTOgwlq0_D79hg_zsRDgDxeg_u1uTAj2jw_S21tYQ0f&share_app_id=1233&share_author_id=7273671089656792107&share_uid=7273671089656792107&tt_from=scan_code&utm_campaign=client_scan_code&utm_medium=1&utm_source=scan_code&_r=1">
                        <TikTokIcon />
                    </a>
                </div>
            </Box>
            <Box
                sx={{
                height: '100px', // Adjust the height as needed
                background: '#f36501',
                clipPath: 'polygon(0 0, 100% 100%, 0 100%)'
                }}
            />
            <Box 
            sx={{ 
                backgroundColor: '#f36501', 
                width: '100%', 
                minHeight: 'calc(100vh - 400px)' // Adjust this value based on other content on your page
            }}
            >
                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", paddingTop: "20px", paddingLeft: "100px", paddingRight: "100px", paddingBottom:"20px"}}>
                    <Card sx={{width: "400px", minHeight: "5vh"}}>
                        <CardContent>
                            <h1 style={{textAlign: "center", fontSize: "35px", color: "#000"}}>Dish & Swish Draft</h1>
                            <div style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
                            <Card sx={{minWidth: "100px", minHeight: "5vh"}}>
                                <CardContent>
                                    <h1 style={{textAlign: "center", fontSize: "20px", color: "#000"}}>Top 5: Centers of All-Time</h1>
                                    <List>
                                        <ListItem>
                                            <ListItemText primary="1. Kareem Abdul-Jabbar" />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary="2. Shaquille O'Neal" />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary="3. Bill Russell" />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary="4. Wilt Chamberlin" />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary="5. Hakeem Olajuwon" />
                                        </ListItem>
                                    </List>
                                </CardContent>
                            </Card>
                            {/* <Card sx={{minWidth: "100px", minHeight: "5vh"}}>
                                <CardContent>
                                    <h1 style={{textAlign: "center", fontSize: "20px", color: "#000"}}>Amadeus</h1>
                                    <List>
                                        <ListItem>
                                            <ListItemText primary="1. Cade Cunningham" />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary="2. Jalen Green" />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary="3. Evan Mobley" />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary="4. Jalen Suggs" />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary="5. Scottie Barnes" />
                                        </ListItem>
                                    </List>
                                </CardContent>
                            </Card> */}
                            </div>
                        </CardContent>
                    </Card>
                    <Card sx={{width: "400px", minHeight: "5vh"}}>
                        <CardContent>
                            <h1 style={{textAlign: "center", fontSize: "35px", color: "#000"}}>Random News</h1>
                            <p style={{fontSize: "22px", fontWeight: "bold"}}>LaMarcus Aldridge Rumored to be a Potential Spurs Player Development Coach</p>
                            <TweetEmbed />
                        </CardContent>
                    </Card>
                    <Card sx={{width: "400px", minHeight: "5vh"}}>
                        <CardContent>
                            <h1 style={{textAlign: "center", fontSize: "35px", color: "#000"}}>Countdown</h1>
                            {/* #5 contender for the 2024 NBA Finals is ... */}
                            <p style={{fontSize: "24px", textAlign: "center"}}>Our #5 contender for the 2024 NBA Finals is ...</p>
                            <img src="/images/nbaLogos/Bucks.png" alt="2024 NBA Finals" style={{width: "100%"}}/>
                        </CardContent>
                    </Card>
                </div>
            </Box>
        </MainLayout>
    );
}
