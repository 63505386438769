import React from 'react';
import MainLayout from '../layouts/main';
import { Box, Button } from '@mui/material';

const primaryButtonStyles = {
    backgroundColor: '#f36501',
    borderRadius: '50px',
    width: "90%",
    padding: '10px',
    marginTop: '10px',
    transition: 'background-color 0.3s', // Add transition for a smooth color change
    '&:hover': {
        backgroundColor: '#ff9900', // Change the background color on hover
    },
};

export default function Contact() {
    return (
        <MainLayout>
            {/* Put the images/blackAndWhite.jpeg to the left and a Contact Us header with a text box where people can type comments */}
            {/* <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', width: '100vw', backgroundColor: '#fff' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '50vw', height: '50vh', backgroundColor: '#fff' }}>
                    <img src="/images/blackAndWhite.jpeg" alt="black and white" style={{ width: '50%', height: '50%' }} />
                    <h1 style={{ fontSize: '2rem', fontWeight: 'bolder' }}>Contact Us</h1>
                    <textarea placeholder="Type your message here..." style={{ width: '50%', height: '50%' }} />
                </Box>
            </Box> */}
            <Box sx={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", height: "100vh"}}>
                <Box sx={{width: "50%"}}>
                    <img src="/images/blackAndWhite.jpeg" alt="black and white" style={{ width: '90%', height: '100%' }} />
                </Box>
                <Box sx={{width: "50%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                    <h1 style={{ fontSize: '5rem', fontWeight: 'bolder' }}>Contact Us</h1>
                    <textarea placeholder="Type your message here..." style={{ width: '90%', height: '50vh' }} />
                    <Button variant="contained" style={primaryButtonStyles}>Send</Button>
                    <Box sx={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                        <p>or</p>
                        <p>e-mail us at dishandswishpod@gmail.com</p>
                    </Box>
                </Box>
            </Box>
        </MainLayout>
    );
}