import React from 'react';
import MainLayout from '../layouts/main';
import { Box } from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';

export default function About() {
    return (
        <MainLayout>
            {/* About Us Page with a picture an About Us Header and a small paragraph in the middle of the page */}
            <Box sx={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                <Box
                    sx={{
                        minHeight: "92vh",
                        color: '#000',
                        textAlign: 'center',
                        width: "400px"
                    }}
                >
                    <p style={{ fontSize: '5rem', fontWeight: 'bolder' }}>About Us</p>
                    <p style={{ fontSize: '1.5rem' }}>We are a basketball podcast with the goal of bringing you a fresh take on basketball from the point of view of two fans. Our passion for the game drives every podcast, ensuring that our listeners receive the latest news and some laughs along the way. We hope all tune in and join our ever-growing community of basketball aficionados!</p>
                </Box>
                <Box sx={{minHeight: "8vh", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                    <p>more info</p>
                    <KeyboardArrowDown sx={{fontSize: "50px"}}/>
                </Box>
                <Box
                    sx={{
                        backgroundColor: '#000',
                        width: "100%",
                    }}
                >
                    <Box sx={{color: "#fff", width: "45%", marginLeft: "5%"}}>
                        <p style={{fontSize: "50px", fontWeight: "bolder"}}>Trevor</p>
                        <p>Trevor is a college hooper turned podcaster. He played basketball JUCO at Western Wyoming where he had success averaging 15ppg and 6rpg in his Sophmore season before transferring to play D2 ball at Fort Lewis College. He now works as a sports director and helps kids develop their love for sports. While he may have hung up the jersey his passion for the game is still alive and well.</p>
                    </Box>
                    <Box sx={{width: "45%", marginLeft: "5%", borderBottom: "5px solid #f36501"}}/>
                    {/* Space */}
                    {/* <Box sx={{height: "5%"}}/> */}
                    <Box sx={{width: "100%",  display: "flex", justifyContent: "center"}}>
                        <img src="/images/LogoBlack.jpg" alt="logo" style={{height: "350px"}} />
                    </Box>
                    <Box sx={{width: "45%", marginLeft: "50%", borderBottom: "5px solid #f36501"}}/>
                    <Box sx={{width: "100%",  display: "flex", justifyContent: "flex-end"}}>
                        <Box sx={{color: "#fff", width: "45%", marginRight: "5%", marginBottom: "5%"}}>
                            <p style={{fontSize: "50px", fontWeight: "bolder"}}>Amadeus</p>
                            <p>Amadeus was a high school hooper who has always maintained a love for the game. After high school he went and got his degree in computer science and now works as a software engineer. Today his connection to basketball remains unbreakable and </p>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </MainLayout>
    );
}