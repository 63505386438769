import React from 'react';
import MainLayout from '../layouts/main';
import { Box } from '@mui/material';

const containerStyles = {
    position: 'relative',
    width: '100vw',
    minHeight: '100vh',
    overflow: 'hidden', // Ensure the pseudo-element doesn't overflow
    display: 'flex',
    flexDirection: 'column',
    '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundImage: 'url("/images/LogoWhite.jpg")',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        filter: 'blur(20px)',
        zIndex: -1 // Keep the pseudo-element below the actual content
    },
};

export default function Shop() {
    return (
        <MainLayout>
            <Box sx={containerStyles}>
                <Box 
                    sx={{ 
                        backgroundColor: '#fff', 
                        width: '100%', 
                        height: '60px' // Adjust this value based on other content on your page
                    }}
                />
                {/* Coming Soon written in the middle */}
                <Box
                    sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    color: '#000',
                    textAlign: 'center',
                    width: "400px"
                    }}
                >
                    <p style={{fontSize: '5rem', fontWeight: 'bolder'}}>COMING SOON</p>
                    <p style={{fontSize: '1.5rem'}}>We are working hard to bring you the best products.</p>
                </Box>
            </Box>
        </MainLayout>
    );
}